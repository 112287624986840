import { Component, Vue } from "vue-property-decorator";

import { STHeader,STFooter } from "@/layout";
import { RollcallModel } from "@/models";
import { StCommon } from "../Common";

type response = { [key: string]: unknown };

@Component<ApStudentRollcallIndexController>({
    components: {
        STHeader,STFooter
    }
})
export default class ApStudentRollcallIndexController extends Vue {
    private rollcallList: object = {};
    private num = 0;
    private page = 1;
    private perpage = 50;
    private maxPage = 0;
    private stid = window.localStorage.getItem('student_stid') as string;
    private token = window.localStorage.getItem('studentToken') as string;

    public created() {
        this.getRollcallList();
    }

    private async getRollcallList() {
        const item = {
            page: this.page,
            perpage: this.perpage,
            stid: this.stid,
            token: this.token,
        }
        const data = await RollcallModel.stGetList(item) as response;
        StCommon.checkToken(data.ERR_CODE as number);
        this.num = data.num as number;
        this.rollcallList = data.list as object;
        this.maxPage = Math.ceil(this.num/this.perpage);
    }

    private getPageList(page: number) {
        this.page = (page < 1) ? 1 : (page > this.maxPage) ?  this.maxPage : page;
        this.getRollcallList();
    }
}