import { Vue } from "vue-property-decorator";

import { AccountModel } from "@/models";
import { ErrorCode } from "@/constant";

type response = { [key: string]: unknown };

class StCommon extends Vue {
  private static sInstance: StCommon;
  private token = window.localStorage.getItem('studentToken') as string;

  public static get instance(): StCommon {
    if (!StCommon.sInstance) {
      StCommon.sInstance = new StCommon();
    }

    return StCommon.sInstance;
  }

  public logout() {
    window.localStorage.clear();

    window.location.href = "/student/login";
  }

  public checkToken(errNo: number) {
    if (errNo === ErrorCode.InvalidToken) {
      this.logout();
      return;
    }
    return;
  }

  //取得所有縣市
  public async getAllCity() {
    const item: { [key: string]: string } = {
      token: this.token,
    }
    const data = await AccountModel.stGetAllCity(item) as response;
    this.checkToken(data.ERR_CODE as number);
    return data.list as object;
  }

  //取得該縣市之鄉鎮市區
  public async getRegion(cid: string) {
    const item: { [key: string]: string } = {
      cid: cid,
      token: this.token,
    }
    const data = await AccountModel.stGetRegion(item) as response;
    this.checkToken(data.ERR_CODE as number);
    return data.list as object;
  }
}

const stCommon = StCommon.instance;

export { stCommon as StCommon };

